<template>
   <div>
    <Loader />
    <div class="wrapper">
       <SidebarStyle :is-admin="true" />
       <HeaderStyle />
       <div class="content-page" id="content-page">
           <transition name="router-anim" enter-active-class="animated  fadeInUp" mode="out-in"
                leave-active-class="animated fadeOut" >
              <router-view />
            </transition>
        </div>
     </div>
    <LayoutFooter />
  </div>
</template>
<script>
import HeaderStyle from '../components/core/partials/HeaderStyle/HeaderStyle.vue'
import SidebarStyle from '../components/core/partials/SidebarStyle/SidebarStyle.vue'
import LayoutFooter from '../layouts/Components/LayoutFooter.vue'
import Loader from '../components/core/loader/Loader'
import { firebaseApp } from '../config/firebase'
export default {
  name: 'Layout2',
  components: {
    Loader,
    HeaderStyle,
    SidebarStyle,
    LayoutFooter
  },
  mounted () {
    document.body.classList = 'sidebar-main'
  },
  destroyed () {
    document.body.classList = ''
  },
  created () {
    firebaseApp.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        // console.log(user.email)
        // console.log('Loged in')
        localStorage.setItem('uid', user.uid)
        localStorage.setItem('userLocal', JSON.stringify(user))
        // console.log(user.uid)
      } else {
        // No user is signed in.
      }
    })
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
